import logoTipo from '../../images/Logo.svg'
import logoMarca from '../../images/Camada1.png'
import styles from './header.module.scss'

import { Link } from 'react-scroll'

export default function Header() {
    return (
        <div className={styles.flex}>
            <img className={styles.logotipo} src={logoTipo} alt="" />
            <img className={styles.logomarca} src={logoMarca} alt="" />
            <Link to="formulario" spy={true} smooth={true} offset={50} duration={500} ><button>Contate Nos</button></Link>
        </div>
    )
}