import styles from './principal.module.scss'
import imgPrincipal from '../../images/01.png'
import clientes from '../../images/clientes.png'

import { Link } from 'react-scroll'

export default function Principal(){
    return(
        <div className={styles.flex}>
            <div className={`${styles.w50} ${styles.textPrincipal}`}>
                <div className={styles.text}>
                    <h1>Potencialize seu faturamento com a Exponect</h1>
                    <div className={styles.ctaPrincipal}>
                        <Link to="formulario" spy={true} smooth={true} offset={50} duration={500} ><button>Começar agora</button></Link>
                        <div className={styles.clientes}>
                            <img src={clientes} alt="" />
                            <div className={styles.textClientes}>
                                <h1>+100</h1>
                                <p>Clientes satisfeitos</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${styles.w50} ${styles.image}`}>
                <img src={imgPrincipal} alt="" />
            </div>
        </div>
    )
}   