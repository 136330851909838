import styles from './footer.module.scss'

import logo3 from '../../images/Logo3.png'
import logo2 from '../../images/Logo2footer.png'

import facebook from '../../images/icons/facebook.png'
import instagram from '../../images/icons/instagram.png'
import linkedin from '../../images/icons/linkedin.png'
import behance from '../../images/icons/behance.png'

export default function Footer(){

    const linkinsta = "https://www.instagram.com/exponect/"
    const linkfacebook = "https://www.facebook.com/profile.php?id=61559839696318"
    const linkbehance = "https://www.behance.net/exponectagncia"
    const linklinkedin = "https://www.linkedin.com/company/exponect/"

    return(
        <div className={styles.footer}>
            <div className={styles.boxFooter}> 
                <div className={styles.boxImg}>
                    <img src={logo2} alt="" />
                </div>
                <div className={styles.boxImgMob}>
                    <img src={logo3} alt="" />
                </div>
                <div className={styles.boxIcons}>
                    <a href={linkfacebook} target="_blank" rel="noreferrer" className={`${styles.circle} ${styles.facebook}`}>
                        <img src={facebook} alt="" />
                    </a>
                    <a href={linkinsta} target="_blank" rel="noreferrer" className={`${styles.circle} ${styles.instagram}`}>
                        <img src={instagram} alt="" />
                    </a>
                    <a href={linkbehance} target="_blank" rel="noreferrer" className={`${styles.circle} ${styles.behance}`}>
                        <img src={behance} alt="" />
                    </a>
                    <a href={linklinkedin} target="_blank" rel="noreferrer" className={`${styles.circle} ${styles.linkedin}`}>
                        <img src={linkedin} alt="" />
                    </a>
                </div>
                <p>Copyright 2024</p>
            </div>
        </div>
    )
}