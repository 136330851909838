import styles from './form.module.scss'

import { useState } from 'react';
import emailjs from '@emailjs/browser';

export default function Form(){
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    function sendEmail(e: { preventDefault: () => void; }){
        e.preventDefault();
        if(name === '' || email === '' || message === ''){
            alert('preencha todos os campos')
            return;
        }

        const templateParams = {
            from_name: name,
            message: message,
            email: email
        }
        
        emailjs.send("service_pimykb3", "template_y03dwoi", templateParams, "d9HlYZAMoZYzoU7Jg")
        .then((res) => {
           // console.log("email enviado", res.status, res.text)
            setName('')
            setEmail('')
            setMessage('')
        }, (err: any) =>{
          //  console.log("erro: ", err)
        })
    }

    return(
    <div id='formulario' className={styles.formulario}>
        <div className={styles.textForm}>
            <h1>Vamos Transformar sua Marca Juntos!</h1>
            <p>Estamos ansiosos para ouvir sobre sua visão e ajudá-lo a alcançar seus 
                objetivos de negócios. Entre em contato hoje para começarmos essa jornada de 
                sucesso!
            </p>
        </div>
        <form action="" onSubmit={sendEmail}>
            <input required type="text" placeholder='Nome' onChange={(e) => setName(e.target.value)} value={name}/>
            <input required type="text" placeholder='Email' onChange={(e) => setEmail(e.target.value)} value={email}/>
            <textarea required placeholder='Mensagem' onChange={(e) => setMessage(e.target.value)} value={message}></textarea>
            <button>Quero vender mais!</button>
        </form>
    </div>
    )
}