import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import styles from './sliderservicos.module.scss'
import elipse from '../../images/Ellipse.png'

export default function SliderServicos(){
    const settings = {
        infinite: true,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 1000,
        arrows:false,
        infinity: true,
        autoplay: true,
        centerMode: true,
        variableWidth: true,
      };
    return(
        <div className={styles.box}>
            <Slider {...settings}>    
                <p >UI/UX Design</p>
                <img src={elipse} alt="" />
                <p >Web Design</p>
                <img src={elipse} alt="" />
                <p >Automação de redes sociais</p>
                <img src={elipse} alt="" />
                <p >Publicidade Online</p>
                <img src={elipse} alt="" />

            </Slider>
        </div>
        
    )
}