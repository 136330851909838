import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Header from './components/header';
import Principal from './components/principal';
import SliderServicos from './components/sliderServicos';
import Sobre from './components/sobre';
import Abordagem from './components/abordagem';
import Servicos from './components/servicos';
import Porque from './components/porque';
import Depoimentos from './components/depoimentos';
import Form from './components/form';
import Footer from './components/footer';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <div className="container">
        <Header/>
        <Principal/>
        <SliderServicos/>
    </div>
    <Sobre/>
    <div className="container">
        <Abordagem/>
        <Servicos/>
        <Porque/>
        <Depoimentos/>
        <Form/>
        <Footer/>
    </div>
  </React.StrictMode>
);


