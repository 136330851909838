import styles from './servicos.module.scss'
import blob1 from '../../images/blobs/Ativo1.svg'
import blob2 from '../../images/blobs/Ativo2.svg'
import blob3 from '../../images/blobs/Ativo3.svg'
import blob4 from '../../images/blobs/Ativo4.svg'
import blob5 from '../../images/blobs/Ativo5.svg'

export default function Servicos(){
    return(
        <>
            <div className={styles.flex}>
                <div className={styles.textFlex1}>
                    <h1>Como Ajudamos as Empresas a Vender Mais</h1>
                </div>
                <div className={styles.card}>
                    <div className={styles.textCard}>
                        <h1>UX/UI Design</h1>
                        <p>Nosso serviço de UX/UI para landing pages cria experiências 
                            envolventes e eficazes, garantindo conversões. Design intuitivo, 
                            visualmente atraente e responsivo para alcançar seus objetivos.
                        </p>
                    </div>
                    <div className={styles.imgCard}>
                        <img src={blob1} alt="" />
                    </div>
                </div>
            </div>
            <div className={styles.flex}>
                <div className={`${styles.card} ${styles.w60}`}>
                    <div className={styles.textCard}>
                        <h1>Gestão de mídias sociais</h1>
                        <p>Desenvolvemos conteúdo exclusivo que permite aos usuários reconhecê-lo em 
                            qualquer lugar. Analisamos concorrentes, perfis de empresas, damos sugestões 
                            para melhorar sua bio e em novas abordagens promocionais.
                        </p>
                    </div>
                    <div className={styles.imgCard}>
                        <img src={blob2} alt="" />
                    </div>
                </div>
                <div className={`${styles.card} ${styles.w40}`}>
                    <div className={styles.textCard}>
                        <h1>Web Design</h1>
                        <p>Web design para landing pages: Transformamos ideias em páginas atraentes 
                            e funcionais que impulsionam 
                            conversões e destacam sua marca.
                        </p>
                    </div>
                    <div className={styles.imgCard}>
                        <img src={blob3} alt="" />
                    </div>
                </div>
            </div>
            <div className={styles.flex}>
                <div className={`${styles.card} ${styles.w40}`}>
                    <div className={styles.textCard}>
                        <h1>Automação de redes sociais</h1>
                        <p>Otmização de processos para landing pages: Otimize operações, economize tempo 
                            e aumente a eficiência em suas campanhas de marketing.
                        </p>
                    </div>
                    <div className={styles.imgCard}>
                        <img src={blob4} alt="" />
                    </div>
                </div>
                <div className={`${styles.card} ${styles.w60}`}>
                    <div className={styles.textCard}>
                        <h1>Publicidade Online</h1>
                        <p>Monitoramos seus dados diariamente.
                        Aumentamos sua performance com foco em conversões e vendas.
                        Apoiamos a tomada de decisão estratégica para um crescimento sustentável e contínuo.
                        </p>
                    </div>
                    <div className={styles.imgCard}>
                        <img src={blob5} alt="" />
                    </div>
                </div>
            </div>
        </>
    )
}