import styles from './sobre.module.scss'
import sobre from '../../images/02.png'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-scroll'
export default function Sobre(){
    return(
        <div className={styles.boxsobre}>
            <div className={styles.back}>
                <div className={styles.wrap}>
                    <div className={styles.image}>
                        <img src={sobre} alt="" />
                    </div>
                    <div className={styles.text}>
                        <h1>Expertise em marketing e tecnologia combinadas.</h1>
                        <p>Com profissionais de diversas áreas de conhecimento, 
                            nós desenvolvemos as técnicas e meios para alcançar 
                            os melhores resultados para seu negócio digital. 
                            Nossa premissa é ter um entendimento completo do 
                            seu negócio, atuando de forma conjunta e transparente, 
                            com um atendimento extremamente próximo e humano.
                        </p>
                        <Link to="formulario" spy={true} smooth={true} offset={50} duration={500} ><button>Fale com um Especialista <FontAwesomeIcon size='1x' style = {{transform: 'rotate(-45deg)' }} icon={faArrowRight} /></button></Link>
                    </div>
                </div>
            </div>
        </div>
    )
}