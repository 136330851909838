import styles from './abordagem.module.scss'

import abordagemImage from '../../images/Card1web.png'
import clientes2 from '../../images/clientes2.png'
import cardtablet from '../../images/Card1tablet.png'
import cardmobile from '../../images/Card1mobile.png'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-scroll';

export default function Abordagem(){
    return(
       <div className={styles.flex}>
            <div className={styles.text}>
                <h1>Nossa Abordagem</h1>
                <div className={styles.box1}>
                    <div className={styles.circle}>
                        <p>8.9</p>
                    </div>
                    <div className={styles.textBox1}>
                        <h1>Taxa geral do cliente</h1>
                        <p>Avaliação satisfatória: Nossa prioridade é a excelência do cliente.</p>
                    </div>
                </div>
            </div>
            <div className={styles.midBox}>
                <img src={abordagemImage} alt="" />
            </div>
            <div className={styles.midBoxTablet}>
                <img src={cardtablet} alt="" />
            </div>
            <div className={styles.midBoxMobile}>
                <img src={cardmobile} alt="" />
            </div>
            <div className={styles.box3}>
                <div className={styles.topBox}>
                    <img src={clientes2} alt="" />
                    <div className={styles.textBox3}>
                        <h1>+100</h1>
                        <p>Clientes satisfeitos</p>
                    </div>
                </div>
                    <h1>Facilitando soluções, simplificando o sucesso.</h1>
                    <Link to="formulario" spy={true} smooth={true} offset={50} duration={500} ><button>Junte-se a nós <FontAwesomeIcon size='1x' style = {{transform: 'rotate(-45deg)' }} icon={faArrowRight} /></button></Link>
            </div>
       </div>
    )
}