import styles from './porque.module.scss'
import icons1 from '../../images/icons1.png'
import icons2 from '../../images/icons2.png'
import icons3 from '../../images/icons3.png'
import image from '../../images/3.png'

export default function Porque(){
    return(
        <div className={styles.flex}>
            <div className={styles.text}>
                <h1>Porquê Escolher A Exponect</h1>
                <div className={styles.boxText}>
                    <div className={styles.icons}>
                        <img src={icons1} alt="" />
                        <div className={styles.line}></div>
                        <img src={icons2} alt="" />
                        <div className={styles.line}></div>
                        <img src={icons3} alt="" />
                    </div>
                    <div className={styles.textContent}>
                        <p>Resultados mensuráveis que impulsionam o crescimento da sua marca.</p>
                        <p>Abordagem inovadora que combina marketing digital e tecnologia avançada.</p>
                        <p>Suporte personalizado e orientação especializada em todas as etapas.</p>
                    </div>
                </div>
            </div>
            <div className={styles.image}>
                <img src={image} alt="" />
            </div>
        </div>
    )
}