import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import cliente1 from  '../../images/depoimentos/Cliente1.png'
import cliente2 from  '../../images/depoimentos/Cliente2.png'
import cliente3 from  '../../images/depoimentos/Cliente3.png'
import cliente4 from  '../../images/depoimentos/Cliente4.png'
import cliente5 from  '../../images/depoimentos/Cliente5.png'

import styles from './depoimentos.module.scss'

export default function Depoimentos(){
    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        slidesToShow: 3,
        speed: 500,
        dots: true,
        arrows: false,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
                centerMode:false,
              }
            }]
      };
    return(
        <div className={styles.depoimentos}>
            <h1>O Que Nossos Clientes <br /> Estão Dizendo</h1>
            <Slider {...settings}>  
            <div className={styles.boxImg}>
                <img src={cliente1} alt="" />
            </div> 
            <div className={styles.boxImg}>
                <img src={cliente2} alt="" />
            </div> 
            <div className={styles.boxImg}>
                <img src={cliente3} alt="" />
            </div> 
            <div className={styles.boxImg}>
                <img src={cliente4} alt="" />
            </div> 
            <div className={styles.boxImg}>
                <img src={cliente5} alt="" />
            </div> 
            </Slider>
        </div>
    )
}